import { AnyAction, combineReducers } from 'redux';
import { TClientConfig } from '../../hexagon/interfaces';

export const deviceType = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_DEVICE_TYPE') {
        return action.payload.deviceType;
    }
    return state;
};

export const name = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_NAME') {
        return action.payload.name;
    }
    return state;
};

export const journeyType = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_JOURNEY_TYPE') {
        return action.payload.journeyType;
    }
    return state;
};

export const origin = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_ORIGIN') {
        return action.payload.origin;
    }
    return state;
};

export const searchUrl = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_SEARCH_URL') {
        return action.payload.search;
    }
    return state;
};

export const fbclid = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_FBCLID') {
        return action.payload.fbclid;
    }
    return state;
};
export const gclid = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_GCLID') {
        return action.payload.gclid;
    }
    return state;
};

export const msclkid = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_MSCLKID') {
        return action.payload.msclkid;
    }
    return state;
};

export const utmCampaign = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_UTM_CAMPAIGN') {
        return action.payload.utm_campaign;
    }
    return state;
};

export const utmSource = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_UTM_SOURCE') {
        return action.payload.utm_source;
    }
    return state;
};

export const utmMedium = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_UTM_MEDIUM') {
        return action.payload.utm_medium;
    }
    return state;
};

export const utmContent = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_UTM_CONTENT') {
        return action.payload.utm_content;
    }
    return state;
};

export const utmTerm = (state = '', action: AnyAction) => {
    if (action.type === 'client/SET_UTM_TERM') {
        return action.payload.utm_term;
    }
    return state;
};

const clientConfigInitialState = {} as TClientConfig;

export const config = (state: TClientConfig = clientConfigInitialState, action: AnyAction) => {
    if (action.type === 'client/SET_CONFIG') return action.payload.config;
    return state;
};

export const clientReducer = combineReducers({
    name,
    deviceType,
    journeyType,
    config,
    origin,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    gclid,
    utmTerm,
    fbclid,
    msclkid,
    searchUrl,
});
