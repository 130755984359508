import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';
import { getClientSelector } from '../../view-models-generators/clientSelector';

interface InvisibleRecaptchaProps {
    reference?: React.RefObject<ReCAPTCHA>;
}

const InvisibleRecaptcha = ({ reference }: InvisibleRecaptchaProps) => {
    const { recaptchaAccess } = useSelector(getClientSelector).client.config;

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    return (
        <ReCAPTCHA
            className="g-recaptcha"
            ref={reference || recaptchaRef}
            sitekey={recaptchaAccess}
            size="invisible"
        />
    );
};
export default InvisibleRecaptcha;
