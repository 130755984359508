import React from 'react';
import { Container, Row } from 'reactstrap';
import useTranslation from '../hooks/useTranslation';

const UsedVehiclePurchaseLoader = () => {
    const { t } = useTranslation();

    return (
        <div className="page page-loader-redirection">
            <Container>
                <Row className="p-5">
                    <h1>{t('buying_project_used_car_intermediate_message_title')}</h1>
                    <>{t('buying_project_used_car_intermediate_message_content')}</>
                </Row>
            </Container>
        </div>
    );
};

export default UsedVehiclePurchaseLoader;
