import { AnyAction } from 'redux';
import { TActionStatus } from '../appState';

type IStatus = {
    status: TActionStatus;
    isValid?: boolean;
};
const verifyRecaptcha = (
    state: IStatus = { status: 'idle', isValid: false },
    action: AnyAction,
) => {
    if (action.type === 'recaptchaVerification/PENDING') {
        state = {
            status: 'pending',
        };
    }
    if (action.type === 'recaptchaVerification/FAILED') {
        state = { status: 'failed', isValid: false };
    }
    if (action.type === 'recaptchaVerification/SUCCESS') {
        state = { status: 'succeeded', isValid: action.payload.isValid }; // API returns always RIGHT
    }
    if (action.type === 'recaptchaVerification/RESET') state = { status: 'idle', isValid: false };

    return state;
};

export const recaptchaReducer = verifyRecaptcha;
