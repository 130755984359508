import { isRight } from 'fp-ts/lib/Either';
import { ThunkResult } from '../../../redux/configureStore';
import * as actionCreators from './actionCreators';
import { RecaptchaGateway } from '../../gateways/recaptchaGateway.interface';

export const verifyRecaptchaUseCase =
    (token: string): ThunkResult<void> =>
    async (dispatch, getState, { recaptchaGateway }: { recaptchaGateway: RecaptchaGateway }) => {
        dispatch(actionCreators.Actions.VerifyRecaptchaPending());
        const { config } = getState().client;
        const result = await recaptchaGateway.verifyRecaptchaToken(config.identifier, token);

        if (isRight(result)) {
            dispatch(actionCreators.Actions.VerifyRecaptchaSucceed(result.right.success));
        } else {
            dispatch(actionCreators.Actions.VerifyRecaptchaFailed());
        }
    };
