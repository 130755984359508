import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    ButtonGroup,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
} from 'reactstrap';
import { getClientSelector } from '../../view-models-generators/clientSelector';
import { getCarDetailsByRegistrationUseCase } from '../../../../hexagon/usecases/getCarDetailsByRegistration/getCarDetailsByRegistration.useCase';
import { getRegistrationSelector } from '../../view-models-generators/registrationSelector';
import useTranslation from '../hooks/useTranslation';
import { getFormSelector } from '../../view-models-generators/formSelectors';
import { identificationTypeUseCase } from '../../../../hexagon/usecases/identificationType/identificationType.useCase';
import useVehicle from '../hooks/useVehicle';
import { InputValidation } from './InputValidation';
import { InputWithValidation } from './InputWithValidation';
import { setRegistrationUseCase } from '../../../../hexagon/usecases/setRegistration/setRegistration.useCase';
import useTracker from '../hooks/useTracker';
import InvisibleRecaptcha from './InvisibleRecaptcha';
import { verifyRecaptchaUseCase } from '../../../../hexagon/usecases/verifyRecaptchaToken/verifyRecaptcha.useCase';
import { getRecaptchaSelector } from '../../view-models-generators/recaptchaSelector';

type RegistrationInputProps = {
    setErrors: any; // Form errors
};

export const RegistrationInput: React.FC<RegistrationInputProps> = ({ setErrors }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        config,
        client: { deviceType },
    } = useSelector(getClientSelector);
    const { status } = useSelector(getRegistrationSelector);
    const { vehicle, vehicleName, registration } = useSelector(getFormSelector);
    const { trackerPushSpoticarEvent, trackerPushSpoticarDetails } = useTracker();

    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState<boolean>(false);
    const [valid, setValid] = useState<boolean>(true);
    const [immat, setImmat] = useState<string>(registration);
    const [submit, setSubmit] = useState<boolean>();
    const recaptchaRef: any = useRef();

    const { resetVehicle } = useVehicle();
    const { isValid: verifiedCaptcha, status: recaptchaStatus } = useSelector(getRecaptchaSelector);

    const submitingRegistration = () => {
        if (valid) {
            resetVehicle();
            dispatch(getCarDetailsByRegistrationUseCase(immat));
            dispatch(setRegistrationUseCase(immat));
        }
    };

    useEffect(() => {
        setImmat(registration);
    }, [dispatch, registration]);

    // Popup appears if license plate known
    useEffect(() => {
        if (status === 'succeeded') {
            setIsOpen(true);
            trackerPushSpoticarDetails({
                brand: 'spoticar',
                virtualPageURL: 'spoticar/vendez-votre-voiture/license-plate-confirmation-request',
                pageName: `spoticar/reprise/central/B2C/used cars/${deviceType}/NA/licence-plate`,
                language: 'fr',
                country: 'fr',
                siteTypeLevel1: 'spoticar',
                siteTypeLevel2: 'reprise',
                siteOwner: 'central',
                siteTarget: 'B2C',
                siteFamily: 'used cars',
                pageCategory: 'form page',
                formsName: 'trade in request',
                mainStepIndicator: '2a',
                mainStepName: 'license plate confirmation request',
            });
        }
        if (status === 'failed') {
            setError(true);
        }
    }, [status]);

    const handleClickOk = async () => {
        // Push to data layer GTM
        trackerPushSpoticarEvent(
            'Forms::TradeinRequest::Select::Validate',
            'step1_immatriculation',
        );
        const token = await recaptchaRef.current?.executeAsync();
        await dispatch(verifyRecaptchaUseCase(token));
        setSubmit(true);

        if (immat.search(new RegExp(config.registrationRegex)) === 0) {
            setValid(true);
        } else {
            setValid(false);
        }
    };

    useEffect(() => {
        if (valid && submit && verifiedCaptcha) {
            submitingRegistration();
            setSubmit(false);
        }
    }, [valid, submit, verifiedCaptcha]);

    const handleKeyDown = async (key: string) => {
        if (key === 'Enter') {
            setSubmit(true);
            const token = await recaptchaRef.current?.executeAsync();
            dispatch(verifyRecaptchaUseCase(token));
        }
    };
    const handleClick = (value: string) => {
        // dispatch(licensePlateValidationUseCase(value));
        setIsOpen(false);
        if (value === 'no') {
            setImmat('');
            resetVehicle();
        } else {
            dispatch(identificationTypeUseCase('registration'));
            trackerPushSpoticarDetails({
                brand: 'spoticar',
                virtualPageURL: 'spoticar/vendez-votre-voiture/license-plate-auto-filled page',
                pageName: `spoticar/reprise/central/B2C/used cars/${deviceType}/NA/form-license-plate`,
                language: 'fr',
                country: 'fr',
                siteTypeLevel1: 'spoticar',
                siteTypeLevel2: 'reprise',
                siteOwner: 'central',
                siteTarget: 'B2C',
                siteFamily: 'used cars',
                pageCategory: 'form page',
                formsName: 'trade in request',
                mainStepIndicator: '2b',
                mainStepName: 'license-plate-auto-filled page',
                vehicleModelBodystyle: 'NA',
                vehicleModelBodystyleLabel: 'NA',
                vehicleBrand: vehicle.make,
            });
        }
    };

    const handleChange = (value: string) => {
        setError(false);
        setErrors({});
        setSubmit(false);
        if (value.length <= 8) setImmat(value.replace(/[^a-zA-Z0-9]/g, ''));
    };

    const toggleModalCancel = () => {
        setIsOpen(!isOpen);
        setImmat('');
    };
    return (
        <>
            <FormGroup className="form-group-registration">
                <Label>
                    {t('license_plate')}{' '}
                    {(status === 'pending' || recaptchaStatus === 'pending') && (
                        <Spinner size="sm" />
                    )}
                </Label>
                <Row>
                    <Col xs={8} sm={5} md={6} lg={8} xl={8}>
                        <InputWithValidation>
                            <Input
                                invalid={error || (submit && !valid && immat !== '')}
                                value={immat}
                                placeholder={t('license_plate_format')}
                                onChange={(e) => handleChange(e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e.key)}
                            />

                            {(immat && <InputValidation valid={submit ? valid : undefined} />) || (
                                <InputValidation valid={undefined} />
                            )}
                        </InputWithValidation>
                    </Col>
                    <Col xs={4}>
                        <Button
                            color="primary"
                            onClick={handleClickOk}
                            disabled={immat.length === 0}
                        >
                            {t('validate')}
                        </Button>
                    </Col>
                </Row>
                <InvisibleRecaptcha reference={recaptchaRef} />
            </FormGroup>
            {!valid && immat && submit && (
                <p className="text-danger small">{t('wrong_license_plate')}</p>
            )}
            {error && (
                <div className="bg-danger text-light p-3 mb-3 small">
                    <FontAwesomeIcon icon={faExclamationTriangle} /> {t('license_plate_unknown')}
                </div>
            )}

            {!verifiedCaptcha && recaptchaStatus === 'succeeded' && (
                <p className="text-danger small">{t('check_recaptcha_form')}</p>
            )}

            <Modal
                isOpen={isOpen}
                toggle={toggleModalCancel}
                className="license-plate-popup"
                returnFocusAfterClose={false}
            >
                <ModalHeader toggle={toggleModalCancel}>
                    {t('popup_license_plate_title')}
                </ModalHeader>
                <ModalBody>
                    <Row className="license-plate-title">
                        {/* To do fix translation with balise html */}

                        <p className="mb-0">
                            {t('popup_license_plate_text')?.replace('[immat]', '')}

                            <b>{immat}</b>
                        </p>
                    </Row>
                    <Row className="license-plate-body">
                        <Col xs={6}>
                            <Row className="pt-2 pb-2">
                                <Col xs={6}>{t('popup_license_plate_brand')}</Col>
                                <Col xs={6} className="font-weight-bold">
                                    {vehicleName.make}
                                </Col>
                            </Row>

                            <Row className="pb-2">
                                <Col xs={6}>{t('popup_license_plate_model')}</Col>
                                <Col xs={6} className="font-weight-bold">
                                    {vehicleName.model}
                                </Col>
                            </Row>

                            <Row className="pb-2">
                                <Col xs={6}>{t('popup_license_plate_body')}</Col>
                                <Col xs={6} className="font-weight-bold">
                                    {vehicleName.body}
                                </Col>
                            </Row>

                            <Row className="pb-2">
                                <Col xs={6}>{t('popup_license_plate_power')}</Col>
                                <Col xs={6} className="font-weight-bold">
                                    {vehicleName.engine} CV
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={6}>{t('popup_license_plate_horse_power')}</Col>
                                <Col xs={6} className="font-weight-bold">
                                    {vehicleName.fiscal}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={6}>
                            <Row className="pt-2 pb-2">
                                <Col xs={6}>{t('popup_license_plate_fuel')}</Col>
                                <Col xs={6} className="font-weight-bold">
                                    {vehicleName.fuel}
                                </Col>
                            </Row>
                            <Row className="pb-2">
                                <Col xs={6}>{t('popup_license_gears')}</Col>
                                <Col xs={6} className="font-weight-bold">
                                    {vehicleName.gear}
                                </Col>
                            </Row>
                            <Row className="pb-2">
                                <Col xs={6}>{t('popup_license_doors')}</Col>
                                <Col xs={6} className="font-weight-bold">
                                    {vehicleName.door}
                                </Col>
                            </Row>

                            <Row className="pb-2">
                                <Col xs={6}>{t('popup_license_registration_date')}</Col>
                                <Col xs={6} className="font-weight-bold">
                                    {vehicleName.month} {vehicleName.year}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="page-index pt-3 footer">
                        <Col xs={8}>
                            <FormGroup
                                className="form-group-licensePlateValidation"
                                id="form_group_licensePlateValidation"
                            >
                                <Label htmlFor="licensePlateValidation">
                                    {t('popup_license_plate_validation')}
                                </Label>
                                <div className="input-with-validation">
                                    <ButtonGroup>
                                        {[
                                            { name: 'yes', value: 'yes' },
                                            { name: 'no', value: 'no' },
                                        ].map((d) => (
                                            <Button
                                                key={d.value}
                                                color="light"
                                                className="form-control non-selected"
                                                onClick={() => handleClick(d.value)}
                                            >
                                                {t(d.name)}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
};
