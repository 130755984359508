import { left, right } from 'fp-ts/Either';
import { BaseApi } from '../../../../hexagon/infra/BaseApi';
import { ApiResponse } from '../../../../hexagon/infra/ApiResponse';

export class HttpRecaptchaGateway extends BaseApi {
    async verifyRecaptchaToken(identifier: string, token: string): Promise<ApiResponse<any>> {
        try {
            const response = await this.post(
                `/recaptcha/check?identifier=${identifier}&response=${token}`,
            );

            return right(response.data);
        } catch (error) {
            return left(error as string);
        }
    }
}
