import { ActionsUnion, createAction } from '../../../redux/customActions';

export const Actions = {
    VerifyRecaptchaPending: () => createAction('recaptchaVerification/PENDING'),
    VerifyRecaptchaFailed: (isValid?: any) =>
        createAction('recaptchaVerification/FAILED', { isValid }),
    VerifyRecaptchaSucceed: (isValid?: any) =>
        createAction('recaptchaVerification/SUCCESS', { isValid }),
    VerifyRecaptchaReset: () => createAction('recaptchaVerification/RESET'),
};

export type ActionsType = ActionsUnion<typeof Actions>;
