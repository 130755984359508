import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import App from './adapters/primary/react/App';
import reportWebVitals from './reportWebVitals';
import store from './redux/startupScript';

const tagManagerArgs = {
    // GTM container ID
    gtmId: 'GTM-N36B9NW',
};

TagManager.initialize(tagManagerArgs);

// Use createRoot instead of ReactDOM.render
const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
